<template>
  <div>
    <v-card flat>
      <v-card-title>
        <!-- Table Title  -->
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-progress-linear :indeterminate="loading"></v-progress-linear>
      <v-data-table
        :headers="headers"
        :items="getOrderList"
        :items-per-page="8"
        class="elevation-1"
        :search="search"
      >
        <template #item.orderId="{item}">
          <!-- <v-icon small class="mr-2" @click="editProduct(props.item.id)">edit</v-icon> -->

          <span @click="checkOrder(item.orderId)">{{item.orderId}}</span>
        </template>
        <template #item.createdAt="{item}">
          <!-- <v-icon small class="mr-2" @click="editProduct(props.item.id)">edit</v-icon> -->
          {{item.createdAt.toDate() | formatDate}}
        </template>
        <template #item.status="{item}">
          <!-- <v-icon small class="mr-2" @click="editProduct(props.item.id)">edit</v-icon> -->
          <v-chip small class="text-uppercase" :color="colorStatus(item.status)">{{item.status}}</v-chip>
        </template>
        <template #item.action="{item}">
          <!-- <v-icon small class="mr-2" @click="editProduct(props.item.id)">edit</v-icon> -->
          <v-row>
            <v-col>
              <v-icon small color="error" @click="removeOrder(item.id)">delete</v-icon>
            </v-col>
            <v-col>
              <v-icon color="success" @click="checkOrder(item.orderId)">mdi-chevron-right</v-icon>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <!-- Snackbar Notifucation Start -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarMessage }}
      <v-btn dark flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- Snackbar Notifucation End -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrderList",
  data() {
    return {
      loading: false, //Loading
      //Snackbar
      snackbar: false,
      snackbarMessage: null,
      snackbarColor: "",
      search: "",
      headers: [
        {
          text: "Order ID",
          align: "left",
          sortable: true,
          value: "orderId"
        },
        { text: "Username", value: "user.name" },
        { text: "Date", value: "createdAt" },
        { text: "Status", value: "status" },
        { text: "Action", sortable: false, value: "action" }
      ],
      new: "success",
      confirmed: "blue"
    };
  },
  computed: {
    ...mapGetters(["getOrderList"]),
    date(date) {
      return date.toDate();
    }
  },

  methods: {
    ...mapActions(["bind", "delete"]),
    colorStatus(status) {
      return status === "new"
        ? "info"
        : status === "processing"
        ? "secondary"
        : status === "on hold"
        ? "warning"
        : status === "confirmed"
        ? "orange"
        : status === "shipped"
        ? "accent"
        : status === "delivered"
        ? "success"
        : status === "canceled"
        ? "error"
        : "";
    },
    checkOrder(id) {
      this.$router.replace("orders/" + id);
    },
    removeOrder(id) {
      this.delete({
        collection: "orders",
        doc: id
      });
    },
    fetchOrders() {
      this.loading = true;
      if (this.getOrderList.length === 0) {
        this.bind({
          collection: "orders",
          data: "orders"
        })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.snackbarMessage = "Please check your internet connection";
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      } else {
        this.loading = false;
      }
    }
  },
  created() {
    this.fetchOrders();
  }
};
</script>
