var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getOrderList,"items-per-page":8,"search":_vm.search},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.checkOrder(item.orderId)}}},[_vm._v(_vm._s(item.orderId))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt.toDate()))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-uppercase",attrs:{"small":"","color":_vm.colorStatus(item.status)}},[_vm._v(_vm._s(item.status))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.removeOrder(item.id)}}},[_vm._v("delete")])],1),_c('v-col',[_c('v-icon',{attrs:{"color":"success"},on:{"click":function($event){return _vm.checkOrder(item.orderId)}}},[_vm._v("mdi-chevron-right")])],1)],1)]}}])})],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" "),_c('v-btn',{attrs:{"dark":"","flat":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }