<template>
  <div>
    <OrderList />
  </div>
</template>

<script>
import OrderList from "./components/OrderList";

export default {
  name: "Orders",
  components: {
    OrderList
  },
  data() {
    return {};
  }
};
</script>

<style></style>
